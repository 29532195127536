import React from 'react'
import { Typography } from "@mui/material"
import { GatsbyImage } from "gatsby-plugin-image"
import { styled } from "@mui/system"


const Wraper = styled('div')(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  maxWidth: "1280px",
  justifyContent: "center",
  alignItems: "center",
  margin: "0 auto",
  // marginBottom: 200,
  "@media (max-width:576px)": {
    margin: 20,
  }
}))

const WrapperTitle = styled("div")(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 50,
  marginBottom: 100,
  "@media (max-width:576px)": {
    marginBottom: 50,
  }
}))

const BlueText = styled("a")({
  color: '#0870E0'
})

const WraperBlock = styled('div')({
  display: 'grid',
  gridTemplateColumns: "1fr 1fr",
  width: '100%',
  alignContent: 'center',
  alignItems: 'center',
  magrinTop: 30,
  "@media (max-width:576px)": {
    display: "flex",
    flexDirection: 'column',
  }
})

const WraperColumn = styled('div')({
  display: 'flex',
  // alignItems: "center",
})

const WraperImg = styled("div")({
  width: "71.9%",
  marginLeft: "18%",
  "@media (max-width:576px)": {
    display: 'none'
  }
})

const TextWraper =  styled("div")({
  "@media (max-width:576px)": {
    width: '100%'
  }
})

const TextBlock =  styled(Typography)({
  fontFamily: 'Merriweather',
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "21px",
  lineHeight: "190%",
  marginRight: 0,
  "@media (max-width:576px)": {
    letterSpacing: "0.001em",
    width: '100%',
    fontSize: "18px",
  }
})

const TextHeader =  styled(Typography)({
  fontFamily: "Heiti TC",
  fontWeight: 400,
  background: "linear-gradient(107.14deg, #0870E0 5.78%, #54A3F9 93.9%)",
  backgroundClip: "text",
  textFillColor: "transparent",
  fontSize: 66,
  "@media (max-width:576px)": {
    width: '100%',
    fontSize: "28px",
  }
})


export default function TwoImageHero({props}) {


  return(
    <Wraper>
      <WrapperTitle>
        <TextHeader variant='h1'>About Us</TextHeader>
      </WrapperTitle>
      <WraperBlock>
        <WraperColumn>
          <TextWraper>
            <TextBlock>
            Founded in 2015, Graphene Ventures is a venture capital firm which focuses on private technology companies' direct investments. <BlueText> Operating from Palo Alto, Toronto, São Paulo and Riyadh</BlueText>, Graphene covers the US and other markets looking to team up with exceptional founders and allows the firm to fulfill its commitment to <BlueText> help its portfolio companies expand into multiple markets</BlueText>. <br/> <br/>  Being lean, Graphene’s team and advisors <BlueText>bring decades of experience in operating, scaling and investing in high growth startups</BlueText>.
            </TextBlock>
          </TextWraper>
        </WraperColumn>
        <WraperColumn>
          <WraperImg><GatsbyImage image={props.gatsbyImageData} alt={'Palo Alto'}/></WraperImg>
        </WraperColumn>
      </WraperBlock>
    </Wraper>

  )
}