import React from 'react'
import { graphql } from "gatsby"
import Layout from "../components/layout"
import HeroTwoImage from '../components/lib/Hero/twoImage'
import OurValues from "../components/investorsAndFounders/ourValues"
import GlobalTeam from "./../components/globalTeam"
import FeedBack from "../components/investorsAndFounders/for-investors/feedback"
import GridLines from '../components/gridLines'
import Seo from "../components/seo"

const AboutUsPage = ({ data }) => {
  return (
    <Layout headerDark={false}>
      <Seo title="About Us" description={`Venture capital firm focused on early-stage tech companies in enterprise software and consumer technology.`} />
      <HeroTwoImage props={data.file.childImageSharp}/>
      <GridLines
        styles={{
            height: 87,
            heightMob: 30,
            marginTop: 40,
            marginTopMob: 20,
            marginBottom: 0,
            marginBottomMob: 0,
            color: "#F8F8F8",
        }}
      />
      <OurValues/>
      <GridLines
        styles={{
          height: 87,
          heightMob: 27,
          marginBottom: 27,
          marginBottomMob: 25,
          color: "#F8F8F8",
          position: "relative",
          background: "white",
          zIndex: 10,
        }}
      />
      <GlobalTeam data={data.strapiOurTeamOrder}/>
      <GridLines
        styles={{
          height: 87,
          heightMob: 27,
          marginTop: 30,
          marginTopMob: 25,
          color: "#F8F8F8",
          position: "relative",
          background: "white",
          zIndex: 10,
        }}
      />
      <FeedBack/>
    </Layout>
  )
}

export default AboutUsPage;

export const query = graphql`
query aboutUs {
  file(name: {eq: "AboutUs"}) {
    id
    childImageSharp {
      gatsbyImageData
    }
  }
  strapiOurTeamOrder {
    order {
      member_1 {
        position
        name
        photo {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      member_2 {
        position
        name
        photo {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
            }
      member_3 {
        position
        name
        photo {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      member_4 {
        position
        name
        photo {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      member_5 {
        position
        name
        photo {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
}
`