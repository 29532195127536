import React from "react"
import { styled } from "@mui/system"
import { Typography } from "@mui/material"
import { GatsbyImage } from "gatsby-plugin-image"
import { useMediaQuery } from "@mui/material"
import { navigate } from "gatsby"

const TeamWrapper = styled("div")({
  width: "100%",
  maxWidth: "1280px",
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
  "@media (max-width:1024px)": {
    maxWidth: "100vw",
    padding: "0 28px",
  },
  "@media (max-width:576px)": {
    // padding: "0 12px",
    height: "auto",
  },
})

const TeamText = styled("div")({
  maxWidth: "907px",
  marginBottom: "90px",
  "@media (max-width:1024px)": {
    maxWidth: "710px",
    marginBottom: "66px",
  },
  "@media (max-width:576px)": {
    maxWidth: "340px",
    marginBottom: "50px",
  },
})

const TeamImage = styled(GatsbyImage)({
  flexShrink: 0,
  display: "flex",
  alignItems: "flex-end",
  // flexGrow: 1,
  // flexBasis: 0,
  width: "100%",
  aspectRatio: "0.929/1",
  "@media (max-width:576px)": {
    aspectRatio: "0.883/1",
  },
  "& img": {
    width: "100%",
    height: "100%",
  },
})


const Button = styled("button")(({ theme }) => ({
  ...theme.buttons.ourTeam,
  userSelect: "none",
  fontWeight: 400,
}))

const TypographyName = styled(Typography)(({ theme }) => ({
  ...theme.typography.globalTeamName,
  marginTop: "17px",
  lineHeigth: "190%",
  fontSize: "20px",
  "@media (max-width:1024px)": {
    // fontSize: "20px",
    lineHeight: "190%",
  },
  "@media (max-width:576px)": {
    fontSize: "14px",
    marginTop: "9px",
  },
}))

const TypographyPosition = styled(Typography)(({ theme }) => ({
  ...theme.typography.globalTeamPosition,
  // maxWidth: "180px",
  "@media (max-width:1024px)": {
    // fontSize: "16px",
  },
  "@media (max-width:576px)": {
    marginTop: "6px",
    fontSize: "12px",
    marginRight: 0,
  },
}))

const PositionContainer = styled(Typography)({
  display: "flex",
  flexGrow: 1,
  alignItems: "center",
  "@media (max-width:576px)": {
    flexGrow: 0,
    marginRight: 0,
  },
})

const TeamViewWrapper = styled("div")({
  position: "relative",
  display: "grid",
  gridTemplateColumns: "repeat(5, minmax(0, 1fr))",
  gridTemplateRows: "auto auto",
  alignItems: "center",
  width: "100%",
  height: "100%",
  columnGap: 36,
  marginBottom: 41,
  "@media (max-width:576px)": {
    gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
    justifyContent: "center",
    columnGap: 22,
    marginBottom: 31,
  },
})

const TeamViewWrapperLast = styled("div")({
  position: "relative",
  display: "grid",
  gridTemplateColumns: "calc(33.3% - 14.5px) calc(33.3% - 14.5px)",
  width: "100%",
  height: "100%",
  justifyContent: "center",
  columnGap: 22,
  marginBottom: 36,
})



const TeamViewPerson = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  height: "100%",
  flexGrow: 1,
  "@media (max-width:576px)": {},
})

const TeamViewPositionContainer = styled("div")({
  display: "flex",
  flexGrow: 1,
  alignItems: "center",
  gridRowStart: 2,
  flexDirection: "column",
  "@media (max-width:576px)": {
    flexGrow: 0,
  },
})

const TeamViewImage = styled(GatsbyImage)({
  flexShrink: 0,
  display: "flex",
  alignItems: "flex-end",
  aspectRatio: "0.929/1",
  width: "100%",
  "& img": {
    objectPosition: "bottom",
    objectFit: "cover",
    aspectRatio: "0.883/1",
    width: "100%",
  },
  "@media (max-width:567px)": {
    aspectRatio: "0.883/1",
    objectFit: "cover",
  },
})

const TeamViewLine = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(5, 1fr)",
  gridTemplateRows: "auto auto",
  position: "absolute",
  width: "100%",
  top: 0,
  columnGap: 36,
  "@media (max-width:576px)": {
    gridTemplateColumns: "repeat(3, 1fr)",
    gridTemplateRows: "auto auto",
    columnGap: 22,
  },
})

const TeamViewDummy = styled("div")({
  width: "100%",
  aspectRatio: "0.929/1",
  "@media (max-width:576px)": {
    aspectRatio: "0.883/1",
  },
})

const TeamViewDummyLine = styled("div")({
  width: "100%",
  height: "1px",
  background:
    "linear-gradient(90deg, rgba(227, 227, 227, 0) 0%, #E3E3E3 51.56%, rgba(227, 227, 227, 0) 100%)",
  gridColumnStart: 1,
  gridColumnEnd: 6,
  gridRowStart: 2,
  "@media (max-width:576px)": {
      gridColumnEnd: 4,
  },
})


const GlobalTeam = ({ data }) => {
  const isMobile = useMediaQuery("(max-width: 576px)")
  const clean_data = Object.entries(data.order).map(item => {
    var clean = {
      name: item[1].name,
      position: item[1].position,
      img: item[1].photo.localFile.childImageSharp.gatsbyImageData,
    }
    return clean
  })
  // if (isMobile){
  //   const element = clean_data.splice(0, 1)[0];
  //   clean_data.splice(3, 0, element);
  // }

  function TeamView() {
    return (
      <TeamViewWrapper>
        <TeamViewLine>
          <TeamViewDummy />
          <TeamViewDummyLine />
        </TeamViewLine>
        {clean_data.map((person, index) => (
          <TeamViewPerson key={`team-member-${index}`}>
            <TeamViewImage
              objectFit="contain"
              placeholder="blurred"
              image={person.img}
              alt={person.name}
            />
            <TeamViewPositionContainer>
              <TypographyName variant="globalTeamName">
                {person.name}
              </TypographyName>
              <TypographyPosition variant="globalTeamPosition">
                {person.position}
              </TypographyPosition>
            </TeamViewPositionContainer>
          </TeamViewPerson>
        ))}
      </TeamViewWrapper>
    )
  }

  function TeamViewMobile() {
    const tempArr_first = [clean_data[1], clean_data[2], clean_data[3]]
    const tempArr_last = [clean_data[0], clean_data[4]]
    return (
      <>
        <TeamViewWrapper style={{gridTemplateColumns: "repeat(3, minmax(0, 1fr))"}}>
        <TeamViewLine>
          <TeamViewDummy />
          <TeamViewDummyLine />
        </TeamViewLine>
          {tempArr_first.map((person, index) => (
            <TeamViewPerson key={`team-member-${index}`} style={{marginBottom: 0}}>
              <TeamImage
                objectFit="cover"
                placeholder="blurred"
                image={person.img}
                alt={person.name}
              />
              <TypographyName variant="globalTeamName">
                {person.name}
              </TypographyName>
              <PositionContainer>
                <TypographyPosition variant="globalTeamPosition">
                  {person.position}
                </TypographyPosition>
              </PositionContainer>
            </TeamViewPerson>
          ))}
        </TeamViewWrapper>
        <TeamViewWrapperLast>
        <TeamViewLine>
          <TeamViewDummy />
          <TeamViewDummyLine />
        </TeamViewLine>
          {tempArr_last.map((person, index) => (
            <TeamViewPerson key={`team-member-${index}`}>
              <TeamImage
                objectFit="cover"
                placeholder="blurred"
                image={person.img}
                alt={person.name}
              />
              <TypographyName variant="globalTeamName">
                {person.name}
              </TypographyName>
              <PositionContainer>
                <TypographyPosition variant="globalTeamPosition">
                  {person.position}
                </TypographyPosition>
              </PositionContainer>
            </TeamViewPerson>
          ))}
        </TeamViewWrapperLast>
      </>
    )
  }

  return (
    <TeamWrapper>
      <TeamText>
        <Typography variant="globalTeam">
          Investment team with{" "}
          <Typography variant="globalTeamMark">
            50 years+ of operational experience
          </Typography>{" "}
          in CEO, CFO and VC Funds roles is a combination that gives us leverage
          to spot unique teams and innovative companies.
        </Typography>
      </TeamText>
      {!isMobile ? <TeamView /> : <TeamViewMobile />}
      <Button onClick={e => navigate("/team")} style={{zIndex: 2}}>OUR TEAM</Button>
    </TeamWrapper>
  )
}

export default GlobalTeam
